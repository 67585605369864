import { Contract } from "@/model/api/Contract";
import { contractsService } from "@services/contracts.service";
import { Options, Prop, Vue } from "vue-property-decorator";
import { ContractsRoutesEnum } from "../../router";


@Options({
})
export default class ContractPage extends Vue {
  @Prop() readonly contractId!: string;

  contract: Contract = null;

  get isNew(){
    return this.contractId === 'new';
  }

  get backRoute() {
    return { name: ContractsRoutesEnum.CONTRACTS_LIST }
  }

  onSave() {
    this.$waitFor(
      async () => {
        if (this.isNew) {
          const response = await contractsService.create(this.contract);

          this.$router.replace({
            name: ContractsRoutesEnum.CONTRACTS_DETAIL,
            params: { contractId: response.id }
          }).then(() =>
            this.$successMessage(this.$t(`contract.messages.create_success`))
          );
        } else {
          const response = await contractsService.updatePatch(this.contract);
          this.$successMessage(this.$t(`contract.messages.update_success`));
        }

      },
      this.$t('Operation failed')
    );
  }

  onDelete(){
    this.$confirmMessage("Are you sure to delete this Contract?")
    .then( r => {
      if (r) {
        this.deleteContract();
      }
    })
  }

  private deleteContract(){
    this.$waitFor(
      async () => {
        await contractsService.deleteBySystem(this.contract);

        this.$router.replace({ name: ContractsRoutesEnum.CONTRACTS_LIST })
          .then( () => 
            this.$successMessage("Contract successfully deleted") 
          );
      },

      "Deleting Contract failed"
    )
  }


  private _loadContract() {
    return this.$waitFor(
      async () => {
        this.contract = await contractsService.getById(+this.contractId);
      },
      "Contract not found"
    )
  }

  async created() {
    if (this.isNew) {
      this.contract = new Contract();
    } else {
      await this._loadContract();
    }
  }

}