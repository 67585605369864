import { BaseApiModel } from "@/model/common/BaseApiModel";

export class Contract extends BaseApiModel {
    name: string;
    price_cycle: number;
    price_hour: number;
    price_ton: number;
    note: string;
    
    min_hours_late_canc : boolean;
    distance_dz_on      : number;
    distance_dz_off     : number;
}